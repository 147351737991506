<template>
  <div>
    <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
      {{ text_snackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="showGoDialog"
      v-if="current_row !== null && current_row !== undefined"
      max-width="90%"
    >
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <div class="title_contents">
              <div class="objective_content">
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <span class="title">{{ this.$t("Name") }}: </span>
                    <span>{{ current_row?.name }}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <span class="title"
                      >{{ `${this.$t("Material")} Code` }}:
                    </span>
                    <span>{{ current_row?.materialCode }}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <span class="title">{{ this.$t("brands.brand") }}: </span>
                    <span>{{ current_row?.brand?.name }}</span>
                  </v-col>
                </v-row>
              </div>
            </div>

            <v-row class="mt-2">
              <v-col cols="12" sm="12" md="12">
                <v-row class="mt-2 text-center">
                  <v-col cols="12" sm="12" md="12">
                    <span
                      style="font-size: 1rem; color: #000; font-weight: 500;"
                      >Chains per Material:</span
                    >
                    <v-btn
                      v-if="importExcelChainPerMaterialTemplatePathName"
                      class="mb-2 ml-2"
                      color="blue darken-1"
                      @click="
                        downloadExcelTemplateGlobal(
                          importExcelChainPerMaterialTemplatePathName
                        )
                      "
                    >
                      <b-icon icon="download" aria-hidden="true"></b-icon>
                      Plantilla Import
                    </v-btn>
                    <v-btn
                      color="success"
                      dark
                      class="mb-2 ml-2"
                      :loading="isSelectingChainsPerMaterial"
                      @click="onUploadExcelChainsPerMaterialClick"
                    >
                      <b-icon
                        icon="file-excel-fill"
                        aria-hidden="true"
                      ></b-icon>
                      {{ buttonTextChainsPerMaterial }}
                    </v-btn>
                    <input
                      ref="uploaderChainsPerMaterial"
                      class="d-none"
                      type="file"
                      accept=".xls,.xlsx"
                      @change="onFileChangedChainsPerMaterial"
                    />
                    <v-btn
                      color="warning"
                      dark
                      class="mb-2 ml-2"
                      @click="downloadExcelChainsPerMaterial"
                    >
                      <b-icon
                        icon="file-excel-fill"
                        aria-hidden="true"
                      ></b-icon>
                      Export
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-2">
                  <v-col cols="12" sm="12" md="12">
                    <subChainsTable
                      :row="this.materialChainsArr"
                      :update_table="update_table"
                      :save="onClickSaveChainData"
                      :deleteItem="onClickDeleteChainData"
                      ref="subChainsTable"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click.stop="showGoDialog = false"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import subChainsTable from "@/components/subChainsTable";

export default {
  name: "goDialogMaterial",
  props: [
    "value",
    "row",
    "reloadTable",
    "importExcelChainPerMaterialTemplatePathName",
  ],
  components: {
    subChainsTable,
  },
  data: function() {
    return {
      current_row: null,
      materialChainsArr: [],

      isProgressing: false,
      isUploadingExcelFile: false,
      selectedFile: null,
      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      isSelectingChainsPerMaterial: false,
      selectedFileIdposPerMaterial: null,
      defaultbuttonTextChainsPerMaterial: "Import",
    };
  },
  watch: {
    async row(newVal, oldVal) {
      this.current_row = newVal;
      console.log("this.current_row - ", this.current_row);
      if (newVal) {
        await this.init(newVal);
      }
    },
  },
  computed: {
    showGoDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    buttonTextChainsPerMaterial() {
      return this.isSelectingChainsPerMaterial
        ? "Uploading..."
        : this.defaultbuttonTextChainsPerMaterial;
    },
  },
  methods: {
    async onClickDeleteChainData(item) {
      console.log(item);
      if (window.confirm(this.$t("confirmdelete"))) {
        try {
          await ApiService.delete(`brands/material/deletechains/${item.id}`);
          logInfo("Éxito !");
          await this.update_table();
        } catch (error) {
          logError("Error occured !");
        }
      }
    },
    async onClickSaveChainData(editedItem, editedIndex) {
      console.log(editedItem, editedIndex);
      if (editedIndex > -1) {
        let postData = {
          id: editedItem.id,
          status: editedItem.status,
        };
        try {
          await ApiService.post(
            `brands/material/update_material_chains`,
            postData
          );
          logInfo("Éxito !");
          await this.update_table();
          this.$refs.subChainsTable.close();
        } catch (error) {
          logError("Error occured !");
        }
      }
    },
    async init() {
      if (this.current_row.material_chains?.length > 0) {
        this.materialChainsArr = this.current_row.material_chains.map((el) => {
          let push_item = { ...el };
          push_item.chainName = "-";
          push_item.subChainName = "-";
          push_item.sub2ChainName = "-";
          if (el.ChainPerMaterial)
            push_item.chainName = el.ChainPerMaterial.name;
          if (el.subChainPerMaterial)
            push_item.subChainName = el.subChainPerMaterial.name;
          if (el.sub2ChainPerMaterial)
            push_item.sub2ChainName = el.sub2ChainPerMaterial.name;
          return push_item;
        });
      } else {
        console.log("INIT CHAINS");
        this.materialChainsArr = [];
        const {
          isEmptyDefaultChainsOfBrand,
          isEmptyChainsPerMaterial,
        } = await ApiService.get(
          `brands/material/init_chains/${this.current_row.id}`
        );
        if (!isEmptyDefaultChainsOfBrand && !isEmptyChainsPerMaterial) {
          this.updatedRowData(this.current_row);
        }
      }
    },

    async downloadExcelChainsPerMaterial() {
      let fetch_url =
        "brands/material/downloadexcel_chainsPerMaterial/" +
        this.current_row.id;
      const response = await ApiService.get(fetch_url, {
        responseType: "blob",
      });
      download(response, "chainsPerMaterial.xlsx");
    },
    onUploadExcelChainsPerMaterialClick() {
      this.isSelectingChainsPerMaterial = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingChainsPerMaterial = false;
        },
        { once: false }
      );
      this.$refs.uploaderChainsPerMaterial.click();
    },
    async onFileChangedChainsPerMaterial(e) {
      this.selectedFileIdposPerMaterial = e.target.files[0];
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelectingChainsPerMaterial = true;
        let data = new FormData();
        data.append("file", this.selectedFileIdposPerMaterial);
        this.$refs.uploaderChainsPerMaterial.value = "";
        try {
          const response = await ApiService.post(
            `brands/material/uploadexcelChainsPerMaterial/` +
              this.current_row.id,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`,
              },
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message,
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelectingChainsPerMaterial = false;
          await this.reloadTable();
          await this.updatedRowData(this.current_row);
        } catch (error) {
          console.log(error);
          this.isSelectingChainsPerMaterial = false;
        }
      }
    },
    async update_table() {
      await this.reloadTable();
      await this.updatedRowData(this.current_row);
    },
    async updatedRowData(row_data) {
      let fetch_url = "brands/material/getitem/" + row_data.id;
      try {
        const resp = await ApiService.get(fetch_url);
        this.current_row = resp.data;
        await this.init();
      } catch (error) {}
    },
  },
  async updated() {},
  async mounted() {},
};
</script>

<style>
.objective_content .row span {
  color: #000;
  font-size: 1rem;
}
.objective_content .row span.title {
  font-weight: bold;
  font-size: 1rem !important;
}
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.obj-title {
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: 500 !important;
  font-size: 1.25rem !important;
}
.title_contents {
  border: 3px solid #727272;
  border-radius: 5px;
}
.top_title h4 {
  margin: 0;
}
.top_title {
  padding: 15px 15px 15px 15px;
  border-bottom: 3px solid #727272;
}
.objective_content {
  padding: 15px;
}
</style>
